// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-consulting-js": () => import("/opt/build/repo/src/pages/consulting.js" /* webpackChunkName: "component---src-pages-consulting-js" */),
  "component---src-pages-environment-js": () => import("/opt/build/repo/src/pages/environment.js" /* webpackChunkName: "component---src-pages-environment-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-success-js": () => import("/opt/build/repo/src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-technology-js": () => import("/opt/build/repo/src/pages/technology.js" /* webpackChunkName: "component---src-pages-technology-js" */),
  "component---src-pages-template-elements-js": () => import("/opt/build/repo/src/pages/template_elements.js" /* webpackChunkName: "component---src-pages-template-elements-js" */),
  "component---src-pages-template-generic-js": () => import("/opt/build/repo/src/pages/template_generic.js" /* webpackChunkName: "component---src-pages-template-generic-js" */),
  "component---src-pages-template-landing-js": () => import("/opt/build/repo/src/pages/template_landing.js" /* webpackChunkName: "component---src-pages-template-landing-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

